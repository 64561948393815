import React from "react";
// import Accordion from "./Accordion";
import map from "../assets/img/lekki_map.png";
import Accordion from "./Accordion";

const CandidateInfo = ({ state, position }) => {
  return (
    <div className="candidates-info">
      <div className="map">
        <img
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${position}&size=400x400&zoom=15&markers=color:red%7C${position}&key=AIzaSyAfH6RriIeqgnV9VAt_FXvA0P%20-%20aKLwM_s0`}
          alt=""
        />
      </div>
      <div className="stears-live">
        <p>
          Track live election results on{" "}
          <span className="stears-link">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.stears.co/elections/2023/"
            >
              Stears Elections
            </a>
          </span>
        </p>
      </div>
      <Accordion state={state} />
    </div>
  );
};

export default CandidateInfo;
