import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "../components/Landing";
import Results from "../components/Results";

const Routers = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/results" element={<Results />} />
          {/* <Route
            path="customer/:section/:table"
            element={<ProductDisplay cart={cart} setCart={setCart} />}
          />
          <Route path="check/:section/:table" element={<CheckCode />} />
          <Route path="qr" element={<QrGen />} />
          <Route path="/admin/:section" element={<Admin />} />

          <Route
            path="customer/:section/:table/cart"
            element={<Cart cart={cart} setCart={setCart} />}
          />
          <Route
            path="customer/:section/:table/:product"
            element={<SingleProduct cart={cart} setCart={setCart} />}
          />

          <Route path="*" element={<h1>Page Not Found</h1>} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routers;
