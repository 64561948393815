// import logo from './logo.svg';
import "./App.css";
import Routers from "./routes/Routers";
import "./styles/App.scss";

function App() {
  return <Routers />;
}

export default App;
