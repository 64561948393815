import React from "react";
// import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import WhereToVote from "./WhereToVote";
import CandidateInfo from "./CandidateInfo";
import Autocomplete from "react-google-autocomplete";
import { MdHowToVote, MdOutlineHelpOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import Api from "./API";
import logo from "../assets/img/HelpMeVoteLogo.svg";

const options = {
  types: ["address"],
  componentRestrictions: { country: "ng" },
};

const Results = () => {
  // const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const { candidate } = useParams();
  const state = useLocation().state;
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [street, setStreet] = useState("");
  const [allData, setAllData] = useState([]);

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    const position = searchParams.get("position");
    if (!position) return;
    let coords = position.split(",");
    if (coords.length !== 2) return;
    setLat(coords[0]);
    setLng(coords[1]);
    setStreet(searchParams.get("street"));
    getLocationData(coords[0], coords[1]);
  }, []);

  useEffect(() => {
    candidate && candidate === "candidate" && setActive(false);
  }, [candidate]);

  const getLocationData = (lat, lng) => {
    const API = new Api();
    setAllData([]);
    API.getData(lat, lng)
      .then((res) => {
        setAllData(res?.data?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div className="results-page">
      <div className="header">
        <Link to={"/"}>
          <div className="left">
            <div className="title-icon">
              <img src={logo} alt="" />
            </div>
            <h2 className="title">HelpMeVote.ng</h2>
          </div>
        </Link>

        <div className="right">
          <div className="help">
            <a
              href="mailto:helpmevoteng@gmail.com?subject=Report%20a%20problem&body=Thank%20you%20so%20much%20for%20using%20HelpMeVote.ng.%20Please%20share%20any%20feedback%20you%20have%20for%20us%20here.%20Thanks."
              target="_blank"
              rel="noreferrer"
            >
              <MdOutlineHelpOutline />
            </a>
          </div>
        </div>
      </div>
      <div className="top">
        <p>SHOWING RESULTS FOR:</p>
        <div className="form">
          {/* <input
            className="input-container"
            type="text"
            placeholder="20 Admiralty Way, Lekki"
          /> */}
          <Autocomplete
            options={options}
            apiKey={"AIzaSyAfH6RriIeqgnV9VAt_FXvA0P - aKLwM_s0"}
            onPlaceSelected={(place) => {
              setLat(place.geometry.location.lat());
              setLng(place.geometry.location.lng());
              setStreet(place.formatted_address);
              setSearchParams({
                position: `${place.geometry.location.lat()},${place.geometry.location.lng()}`,
                street: place.formatted_address,
              });
              getLocationData(
                place.geometry.location.lat(),
                place.geometry.location.lng()
              );
            }}
            className="input-container"
            type="text"
            defaultValue={street}
            placeholder="e.g. 20 admiralty way, Lekki"
          />
          <button onClick={() => getLocationData(lat, lng)}>Search</button>
        </div>

        <div className="mdata">
          <div className="left">
            <h2>Nigeria 2023 Election</h2>
            <p className="date">FEB 25, 2023 & MAR 11, 2023</p>
          </div>
          <div className="date-picker">
            <img src="" alt="" />
          </div>
        </div>
        <div className="tabs">
          <ul>
            <li
              onClick={() => setActive(true)}
              className={`tab ${active ? "tab-active" : null}`}
            >
              <p className="item">CANDIDATES INFO</p>
            </li>
            <li
              onClick={() => setActive(false)}
              className={`tab ${!active ? "tab-active" : null}`}
            >
              <p className="item">WHERE TO VOTE</p>
            </li>
          </ul>
          <div className={`underline ${!active ? "right_line" : null}`}>
            <div className={`active-1`}></div>
            <div className="active-2"></div>
          </div>
        </div>
      </div>

      {allData.length > 0 && (
        <div className="tab-contents">
          {active ? (
            <CandidateInfo state={allData} position={`${lat},${lng}`} />
          ) : (
            <WhereToVote />
          )}
        </div>
      )}
      {allData.length === 0 && <div className="loader"><div class="lds-ripple"><div></div><div></div></div></div>}

      <div className="footer">
        <div className="about">
          <h1>About HelpMeVote</h1>
          <p>
            HelpMeVote is a non-partisan platform dedicated to providing
            accurate and up-to-date information on candidates and voting
            locations for the upcoming Nigerian election. Our goal is to help
            Nigerian citizens exercise their right to vote and make informed
            decisions. We strive to be a reliable source of information and
            encourage civic engagement.
          </p>
          <p className="disclaimer">
            This tool is powered by data from Stears Elections. If any data is
            incorrect please share it with us at{" "}
            <span>
              <a
                href="mailto:helpmevoteng@gmail.com?subject=Report%20a%20problem&body=Thank%20you%20so%20much%20for%20using%20HelpMeVote.ng.%20Please%20share%20any%20feedback%20you%20have%20for%20us%20here.%20Thanks."
                target="_blank"
                rel="noreferrer"
              >
                helpmevoteng@gmail.com
              </a>
            </span>
          </p>
        </div>
        <div className="footer-links">
          <div className="home">
            <Link to={"/"}>HelpMeVote.ng</Link>
            <p className="source">
              This tool is powered by data from{" "}
              <span className="stears-link">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.stears.co/elections/2023/"
                >
                  Stears Elections
                </a>
              </span>
            </p>
          </div>

          <div className="menu">
            <a href="https://docs.google.com/document/d/1Sr_7VFCmp06iMUPhFhwGLqVtzYmzklsKeX3cDdklx8c/edit">
              Terms of service
            </a>
          </div>
          <div className="menu">
            <a href="https://docs.google.com/document/d/1Sr_7VFCmp06iMUPhFhwGLqVtzYmzklsKeX3cDdklx8c/edit">
              Privacy policy
            </a>
          </div>
          <div className="menu">
            <a href="https://docs.google.com/document/d/e/2PACX-1vS7x9z8dVP0ssWYYl1K44qwtgZ5DHnoeF63_1tO0jnQpUFUXoY6l_Bzxc3q-_YwBcw207urhCSniV8F/pub">
              Team
            </a>
          </div>
          <div className="credit">
            <p>Made with ❤️ in Nigeria</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
