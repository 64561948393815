import axios from "axios";

export default class Api {
  constructor() {
    this.base_url = `https://naija-election-candidates-2023-2kfcnvmreq-ew.a.run.app`;
  }

  getData(lat, lng) {
    return axios.get(`${this.base_url}/find?position=${lat},${lng}`);
  }
  reverseGeocode(lat, lng) {
    return axios.get(`${this.base_url}/find/reverse?position=${lat},${lng}`);
  }
}
