import React from "react";
// import map from "../assets/img/lekki_map.png";
import pvc from "../assets/img/pvc-hero.svg";
import puscreen from "../assets/img/pu-screenshot.png";

const WhereToVote = () => {
  return (
    <>
      <div className="map">
        <img src={pvc} alt="" />
      </div>
      <div className="details">
        <p className="title">Find your polling unit in 3 steps</p>
      </div>

      <div className="details">
        <p className="subtitle">
          <strong>Step 1</strong>
        </p>
        <p className="venue">
          Get your PVC and note the 9 digit code in the top left corner labelled{" "}
          <strong>"CODE"</strong>.
        </p>

        <p className="subtitle">
          <strong>Step 2</strong>
        </p>
        <p className="venue">
          Visit{" "}
          <span>
            <a
              href="https://cvr.inecnigeria.org/pu"
              target="_blank"
              rel="noreferrer"
            >
              INEC's Polling Unit Locator
            </a>
          </span>{" "}
          and use the 9 digit CODE to fill out the drop downs as illustrated
          below.
          <img src={puscreen} alt="" />
        </p>

        <p className="subtitle">
          <strong>Step 3</strong>
        </p>
        <p className="venue">
          Click “Get Directions” on the INEC site to get turn-by-turn directions
          of how to get to your polling unit.
        </p>
        <button>
          <a
            href="https://cvr.inecnigeria.org/pu"
            target="_blank"
            rel="noreferrer"
          >
            Get Started
          </a>
        </button>
      </div>
      {/* <div className="details">
        <p className="title">Election Day Voting Site</p>
        <div className="venue">
          <p>Nigerian Police Force - Barracks - Falomo</p>
        </div>
        <div className="address">
          <p>Awolowo Rd, Ikoyi 106104, Lagos</p>
          <p>1.1 miles away</p>
        </div>
        <p className="title">Polling Location Hours</p>
        <div className="time">
          <p>7am - 7pm</p>
        </div>

        <button>Get Directions</button>
      </div> */}
    </>
  );
};

export default WhereToVote;
