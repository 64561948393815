import React from "react";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { MdHowToVote } from "react-icons/md";
import { MdOutlineHelpOutline } from "react-icons/md";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { BiCurrentLocation } from "react-icons/bi";
import Api from "./API";
import { useGeolocated } from "react-geolocated";
import logo from "../assets/img/HelpMeVoteLogo.svg";

import ReactGA from "react-ga4";
ReactGA.initialize("G-FE6DHYM4Y9");
const Landing = () => {
  const navigate = useNavigate();
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  const options = {
    types: ["address"],
    componentRestrictions: { country: "ng" },
  };
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [street, setStreet] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isGeolocationAvailable && isGeolocationEnabled) {
      if (coords) {
        setLat(coords.latitude);
        setLng(coords.longitude);
      }
    }
  }, [coords, isGeolocationAvailable, isGeolocationEnabled]);
  // console.log(lat, lng);

  const getLocationData = (lat, lng) => {
    const API = new Api();
    if (street === "") {
      API.reverseGeocode(coords.latitude, coords.longitude)
        .then((res) => {
          navigate({
            pathname: "/results",
            search: `?position=${lat},${lng}&street=${res?.data?.data.formattedAddress}`,
          });
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      navigate({
        pathname: "/results",
        search: `?position=${lat},${lng}&street=${street}`,
      });
    }
  };
  return (
    <>
      <div className="landingpage">
        <div className="header">
          <Link to={"/"}>
            <div className="left">
              <div className="title-icon">
                <img src={logo} alt="" />
              </div>
              <h2 className="title">HelpMeVote.ng</h2>
            </div>
          </Link>
          <div className="right">
            <div className="help">
              <a
                href="mailto:helpmevoteng@gmail.com?subject=Report%20a%20problem&body=Thank%20you%20so%20much%20for%20using%20HelpMeVote.ng.%20Please%20share%20any%20feedback%20you%20have%20for%20us%20here.%20Thanks."
                target="_blank"
                rel="noreferrer"
              >
                <MdOutlineHelpOutline />
              </a>
            </div>
          </div>
        </div>
        <div className="content">
          <h1>Find the best candidates around you.</h1>
          <div className="tutorial">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/s5ZqLZi7Jjo"
              title="First Time Voter: 5 Things to Know"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            Enter the address where you are registered to vote to get started
          </p>
          {/* <App /> */}
          <div className="form">
            {/* <input
            className="input-container"
            type="text"
            placeholder="20 admiralty way, Lekki"
          /> */}
            <Autocomplete
              options={options}
              apiKey={"AIzaSyAfH6RriIeqgnV9VAt_FXvA0P - aKLwM_s0"}
              onPlaceSelected={(place) => {
                setStreet(place.formatted_address);
                setLat(place.geometry.location.lat());
                setLng(place.geometry.location.lng());
              }}
              className="input-container"
              type="text"
              placeholder="e.g 20 admiralty way, Lekki"
            />
            <button onClick={() => getLocationData(lat, lng)}>Search</button>
            <button
              className="geolocat"
              onClick={() => {
                if (isGeolocationAvailable && isGeolocationEnabled) {
                  ReactGA.event({
                    category: "Accordion",
                    action: "Accordion opened",
                  });
                  getLocationData(lat, lng);
                } else {
                  if (isGeolocationEnabled) {
                    getPosition();
                  } else {
                    alert("Geolocation is not enabled");
                  }
                }
              }}
            >
              <span className="geoicon">
                <BiCurrentLocation />
              </span>
              <p>Or use your current location</p>
            </button>
          </div>
        </div>
        <div className="footer">
          <div className="about">
            <h1>About HelpMeVote</h1>
            <p>
              HelpMeVote is a non-partisan platform dedicated to providing
              accurate and up-to-date information on candidates and voting
              locations for the upcoming Nigerian election. Our goal is to help
              Nigerian citizens exercise their right to vote and make informed
              decisions. We strive to be a reliable source of information and
              encourage civic engagement.
            </p>
            <p className="disclaimer">
              This tool is powered by data from Stears Elections. If any data is
              incorrect please share it with us at{" "}
              <span>
                <a
                  href="mailto:helpmevoteng@gmail.com?subject=Report%20a%20problem&body=Thank%20you%20so%20much%20for%20using%20HelpMeVote.ng.%20Please%20share%20any%20feedback%20you%20have%20for%20us%20here.%20Thanks."
                  target="_blank"
                  rel="noreferrer"
                >
                  helpmevoteng@gmail.com
                </a>
              </span>
            </p>
          </div>
          <div className="footer-links">
            <div className="home">
              <Link to={"/"}>HelpMeVote.ng</Link>
              <p className="source">
                This tool is powered by data from{" "}
                <span className="stears-link">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.stears.co/elections/2023/"
                  >
                    Stears Elections
                  </a>
                </span>
              </p>
            </div>

            <div className="menu">
              <a href="https://docs.google.com/document/d/1Sr_7VFCmp06iMUPhFhwGLqVtzYmzklsKeX3cDdklx8c/edit">
                Terms of service
              </a>
            </div>
            <div className="menu">
              <a href="https://docs.google.com/document/d/1Sr_7VFCmp06iMUPhFhwGLqVtzYmzklsKeX3cDdklx8c/edit">
                Privacy policy
              </a>
            </div>
            <div className="menu">
              <a href="https://docs.google.com/document/d/e/2PACX-1vS7x9z8dVP0ssWYYl1K44qwtgZ5DHnoeF63_1tO0jnQpUFUXoY6l_Bzxc3q-_YwBcw207urhCSniV8F/pub">
                Team
              </a>
            </div>
            <div className="credit">
              <p>Made with ❤️ in Nigeria</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
