import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import ReactGA from "react-ga4";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { HiChevronDown } from "react-icons/hi";
import { BsArrowUpRight } from "react-icons/bs";
// import { HiChevronDown } from "react-icons/hi";
// import CandidateCard from "./CandidateCard";
import defaultavi from "../assets/img/placeholder_candidate_avi.svg";
import partylogo from "../assets/img/party-logo.svg";
import NNPP from "../assets/img/parties/NNPP.png";
import NRM from "../assets/img/parties/NRM.png";
import APC from "../assets/img/parties/APC.png";
import PDP from "../assets/img/parties/PDP.png";
import ADC from "../assets/img/parties/ADC.png";
import SDP from "../assets/img/parties/SDP.png";
import BP from "../assets/img/parties/BP.png";
import YPP from "../assets/img/parties/YPP.png";
import ZLP from "../assets/img/parties/ZLP.png";
import ACCORD from "../assets/img/parties/ACCORD.png";
import AA from "../assets/img/parties/AA.png";
import AAC from "../assets/img/parties/AAC.png";
import ADP from "../assets/img/parties/ADP.png";
import APM from "../assets/img/parties/APM.png";
import APP from "../assets/img/parties/APP.png";
import LP from "../assets/img/parties/LP.png";
import { useState, useEffect } from "react";

ReactGA.initialize("G-FE6DHYM4Y9");
export default function AccordionTest({ state }) {
  const [races, setRaces] = useState([]);
  useEffect(() => {
    const rr = [];
    state?.map((data) => {
      !rr?.includes(data?.race) && rr?.push(data?.race);
      return null;
    });
    setRaces(rr);
  }, [state]);
  // console.log(races);

  const getPosition = (rr) => {
    if (rr === "house") return "member";
    if (rr === "president") return "president";
    if (rr === "senate") return "senator";
    if (rr === "governor") return "governor";
  };

  const getTitle = (rr) => {
    if (rr === "house") return "House of Reps";
    if (rr === "state_house") return "House of Assembly";
    return titleCase(rr);
  };

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getPartyLogo = (party) => {
    if (party === "NNPP") return NNPP;
    if (party === "NRM") return NRM;
    if (party === "APC") return APC;
    if (party === "PDP") return PDP;
    if (party === "ADC") return ADC;
    if (party === "SDP") return SDP;
    if (party === "BP") return BP;
    if (party === "YPP") return YPP;
    if (party === "ZLP") return ZLP;
    if (party === "ACCORD") return ACCORD;
    if (party === "AA") return AA;
    if (party === "AAC") return AAC;
    if (party === "ADP") return ADP;
    if (party === "APM") return APM;
    if (party === "APP") return APP;
    if (party === "LP") return LP;
    return partylogo;
  };

  const titleFunction = (title) => {
    if (title === "senate") return "https://nass.gov.ng/about/item/9#";
    if (title === "house") return "https://nass.gov.ng/about/item/17";
    if (title === "governor") return "https://nass.gov.ng/about/item/9#";
    if (title === "president") return "https://en.wikipedia.org/wiki/President_of_Nigeria";
    return "https://en.wikipedia.org/wiki/President_of_Nigeria";
  };

  return (
    <>
      {races?.map((rr, k) => (
        <Accordion key={k} allowZeroExpanded onChange={(args) => {
          if (args.length > 0) {
            ReactGA.event({
              category: "Accordion",
              action: "Accordion opened",
              label: `${getTitle(rr)} (${state?.filter((ss) => ss?.race === rr)[0]?.constituency})`,
            });
          }
        }}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="office">
                  <div className="post">
                    <h1>
                      {getTitle(rr)} <span>(</span>
                      <span>
                        {
                          state?.filter((ss) => ss?.race === rr)[0]
                            ?.constituency
                        }
                      </span>
                      <span>)</span>
                    </h1>
                  </div>
                  <div className="chevron">
                    <div className="count">
                      <p>{state?.filter((ss) => ss?.race === rr)?.length}</p>
                      <HiChevronDown />
                    </div>
                  </div>
                </div>
                {(rr === "governor" || rr === "state_house") && (
                  <div className="vote-date">
                    <p>Vote on March 11</p>
                  </div>
                )}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="candidates">
                <div className="roles">
                  <a
                    href={titleFunction(rr)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    What does a {getPosition(rr)} do?
                  </a>
                  <div className="external">
                    <BsArrowUpRight />
                  </div>
                </div>
                {/* <CandidateCard /> */}
                {state
                  ?.filter((ss) => ss?.race === rr)
                  .map((st, i) => (
                    <div key={i} className="candidate-card">
                      <div className="topp">
                        <div className="avi">
                          <img src={defaultavi} alt="" />
                        </div>
                        <div className="name">
                          <a
                            href={`https://www.google.com/search?q=${st?.name}&rlz=1C5CHFA_enNG1001NG1001&oq=Yishawu+Gbolahan+Rufai+Olusegunalabi&aqs=chrome..69i57j69i59.567j0j9&sourceid=chrome&ie=UTF-8`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <h2>
                              {st?.name}
                              {st?.isIncumbent && (
                                <span className="incumbent">in office</span>
                              )}
                            </h2>
                          </a>
                        </div>
                      </div>
                      <div className="c-details">
                        <div className="c-info">
                          <p className="subtitle">PARTY</p>
                          <div className="info party">
                            <img
                              className="party-logo"
                              src={getPartyLogo(st?.partyCode)}
                              alt=""
                            />
                            <p className="value">{st?.partyCode}</p>
                          </div>
                        </div>
                        {st?.runningMate.length > 0 && (
                          <div className="c-info ally">
                            <p className="subtitle">RUNNING MATE</p>
                            <a href="/">
                              <p className="value">{st?.runningMate}</p>
                            </a>
                          </div>
                        )}

                        {/* <div className="c-info">
                    <p className="subtitle">ABOUT</p>
                    <div className="info">
                      <p className="value">
                        Obanikoro Ibrahim is a Nigerian politician at the House
                        of Representatives level. Obanikoro Ibrahim currently
                        serves as the Federal Representative ...
                        <span>
                          <a href="https://ibo.org.ng/">Learn More</a>
                        </span>
                      </p>
                    </div>
                  </div> */}
                        <div className="c-info contact">
                          <p className="subtitle">GET IN TOUCH</p>
                          <div className="info">
                            <p className="value">ibocampaign@gmail.com</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      ))}
    </>
  );
}
